<template>
<div class="tableList">
  <div class="gradeMain table">
    <div class="grade">请在 {{startTIme}}~{{endTime}} 内完成评分！<span class="info">注：评分为百分制</span></div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column width="60px" label="序号" >
        <template #default="scope">
            {{scope.$index+1}}
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" prop="bidScoringMes" label="评标内容"></el-table-column>
      <el-table-column :show-overflow-tooltip="true"  prop="score" width="70" label="分值"> </el-table-column>
      <el-table-column :show-overflow-tooltip="true" prop="standard" width="600" label="标准"> </el-table-column>
      <el-table-column :key="index" v-for="(item,index) in supplierList" :label="item.label" >
        <template #default="scope">
            <el-input v-model="scope.row[item.code]" style="height:100%" placeholder="请输入评分"></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</div>
</template>
<script>
import { request } from '@/assets/js/http.js'
export default {
  name: 'ExpertRating',
  props: ['formData'],
  data () {
    return {
      tableData: [],
      supplierList: [],
      startTIme: '',
      endTime: ''
    }
  },
  created: function () {
  },
  watch: {
    formData: function (val) {
      this.startTIme = val.bidEvaluationStartTime
      this.endTime = val.bidEvaluationEndTime
      request('/api/bid/score?bidId=' + val.id, 'get', {}).then((response) => {
        if (response.code === '200') {
          var f = 0
          for (var c of response.data) {
            for (var v of c.list) {
              c[v.supplierId] = v.myScore
              if (f === 0) {
                var tempSupplier = {}
                tempSupplier.code = v.supplierId
                tempSupplier.label = v.supplierName
                this.supplierList.push(tempSupplier)
              }
            }
            f = 1
          }
          this.tableData = response.data
        }
      })
    }
  },
  methods: {
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/form.scss';
.grade{
    font-size: 14px;
    color: gray;
    margin-top: 20px;
    margin-bottom: 20px;
    .info{
      color: #ff6f6f;
      float: right;
    }
}
.gradeMain{
  :deep(.el-input){
    height: 100%;
    line-height: 38px;
    position: absolute;
    left: 0px;
    top: 0px;
    input{
      border: 0;
      background: none;
    }
  }
  :deep(.el-input__inner){
    position: relative;
    top: 0px;
    height: 100%;
  }
}

</style>
